
import { defineComponent, onBeforeMount, reactive, ref, toRefs } from "vue";
import axios from "axios";
import helper from "@/utils/helper";
export default defineComponent({
  name: "summary",
  setup() {
    const dataSource = reactive({
      img: "",
      text: "",
      m_text: "",
    });
    const mobile = ref(false);

    onBeforeMount(() => {
      if (helper.is_mobile()) {
        mobile.value = true;
      }
           
      const url =
        process.env.NODE_ENV == "production"
          ? "/data/summary.json"
          : "/data/summary.json";
      axios.get(url).then((res) => {
        if (res.status == 200) {
          dataSource.img = res.data.img;
          dataSource.text = res.data.text;
          dataSource.m_text = res.data.m_text;
        }
      });
    });

    return {
      ...toRefs(dataSource),
      mobile,
    };
  },
});
